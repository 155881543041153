import classNames from 'classnames'

export const checkDarkMode = () => {
  if (
    localStorage.theme === 'dark' ||
    (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
  ) {
    document.body.setAttribute('data-mode', 'dark')
  } else {
    document.body.removeAttribute('data-mode')
  }
}

export const setDarkMode = () => {
  localStorage.setItem('theme', 'dark')
  document.body.setAttribute('data-mode', 'dark')
}

export const setLightMode = () => {
  localStorage.setItem('theme', 'light')
  document.body.removeAttribute('data-mode')
}

export const setDefaultMode = () => {
  localStorage.removeItem('theme')
  checkDarkMode()
}

export type Intent = 'success' | 'danger' | 'info' | 'warning' | 'primary' | 'secondary'

export const getIntentSurface = (intent?: Intent) =>
  classNames(
    intent === 'info' && 'text-dark bg-surfaceBlue',
    intent === 'success' && 'text-dark bg-surfacePurple',
    intent === 'warning' && 'text-dark bg-surfaceYellow',
    intent === 'danger' && 'text-dark bg-surfacePink',
    intent === 'primary' && 'text-dark bg-surfaceOrange',
    intent === 'secondary' && 'text-dark bg-surfaceGreen',
    !intent && 'text-dark bg-white dark:text-white dark:bg-grey'
  )

export const getIntentInteractive = (intent?: Intent) =>
  classNames(
    intent === 'info' && 'text-dark bg-lightBlue hover:bg-blue',
    intent === 'success' && 'text-white bg-lightPurple hover:bg-purple',
    intent === 'warning' && 'text-dark bg-lightYellow hover:bg-yellow',
    intent === 'danger' && 'text-dark bg-lightPink hover:bg-pink',
    intent === 'primary' && 'text-dark bg-lightOrange hover:bg-orange',
    intent === 'secondary' && 'text-dark bg-lightGreen hover:bg-green',
    !intent && 'text-white bg-dark hover:bg-grey dark:text-white dark:bg-mid dark:hover:bg-grey'
  )
